'use client'

import { usePathname } from 'next/navigation'
import React from 'react'
import Auth from '@/components/wrappers/Auth'

const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const pathname = usePathname()
    const isAuthenticatedRoute = !pathname.startsWith('/auth')

    if (!isAuthenticatedRoute) return <>{children}</>
    return (
        <Auth>
            <>{children}</>
        </Auth>
    )
}

export default AuthProvider
