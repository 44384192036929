'use client'

/* eslint-disable @typescript-eslint/unbound-method */
import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import { useBreakPoints } from '../lib/hooks'
import { signOut } from '@/components/wrappers/Auth'

const IdleTimer = (): JSX.Element | null => {
    const [open, setOpen] = useState(false)
    const [remaining, setRemaining] = useState(Number(process.env.NEXT_PUBLIC_IDLE_TIMEOUT))
    const { isMobile } = useBreakPoints()

    const onIdle = () => {
        setOpen(false)
        signOut()
    }

    const onActive = () => setOpen(false)
    const onPrompt = () => setOpen(true)

    const { getRemainingTime, activate } = useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        timeout: Number(process.env.NEXT_PUBLIC_IDLE_TIMEOUT),
        promptBeforeIdle: 60000,
        crossTab: true,
    })

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined
        if (open) interval = setInterval(() => setRemaining(getRemainingTime()), 1000)

        return () => interval && clearInterval(interval)
    }, [getRemainingTime, open])

    return !isMobile ? (
        <Dialog open={open}>
            <DialogTitle>Are you still here?</DialogTitle>
            <DialogContent>
                <Typography variant="body1" color="text.secondary" fontWeight="medium">
                    You will be automatically logged out in{' '}
                    <Typography component="span" color="text.primary" fontWeight="bold">
                        {Math.floor(remaining / 1000)}
                    </Typography>{' '}
                    seconds
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => activate()}>
                    Continue Session
                </Button>
            </DialogActions>
        </Dialog>
    ) : null
}

export default IdleTimer
